 /****** DESKTOP SITE VARIABLES ******/
 :root {  
    /* DEFINE FONTS FOR THE SITE */
    --serif-fonts: 'Palatino','Times New Roman', Times, serif;
    --sans-fonts: hypatia-sans-pro, sans-serif;

    /* DEFINE COLORS FOR WEBSITE */
    --background-color: #F0F1F4;
    --yellow-highlight :#faf4b6;
    --light-blue: #6d99f9;
    --dark-blue: #43598a;
    --white: white;

    /****** DESKTOP SITE VARIABLES ******/
    /* ALIGNMENT VARIABLES */
    --align-right-to-center: 0 0 0 auto;
    --mobile-inline-block: block;
    --mobile-float-right: none;
    --dRight-mCenter: right;
    --dLeft-mRight: left;
    --start-to-center: start;
    --end-to-center: start;

    /* SIZING VARIABLES */
    --profilePhoto: 200px;
    --contactPhoto: 200px;
    --interior-photo-scale: 75%;
    --mobile-100: 500px;
    
    /* SPACING VARIABLES */
    --main-padding: 9em 0em 12em 0em;
    --article-padding: 6em .5em 4em 16em;
    --photo-margin: 2em;
    --interior-photo-padding: 0em 0em 1.25em 0em;
    --mobile-only-clear: none;
    --mobile-only-padding-top: 0em;
    --mobile-only-move-right: 0em;
    --header-height: 8em;
    --first-button-padding: .75em;
    --show-on-mobile: none;
    --header-h1:32px 0px 0px 32px;


    /* TEXT AND HEADER SIZES */
    --h1-font-size: 4em;
    --h2-font-size: 2em;
    --h3-font-size: 1.4em;
    --header-title-text: 8em;
    --footer-font-size: 1.95em;
    --footer-links: .75em;
    --homepage-buttons: 4em;
    --large-text: .85em;
    --standard-size-text: 1em;
    --half-size-text: .25em;
    --work-button-fonts: 3em;

    /* HEADER VARIABLES */
    --nav-text-top: 1.15em;
    --nav-display: block;
    --nav-margin-top: 20em;
    --navX: 62px;
    --navY: 32px;
    --navFonts: hypatia-sans-pro, sans-serif;
    --header-background: linear-gradient(180deg, var(--background-color) 80%, transparent);

    /* FOOTER VARIABLES */
    --footer-icons: 3em;
    --footer-padding-top: none;
    --footer-height: 1.5em;

    /* INTERIOR PAGE VARIABLES */
    
    /* WORK PAGE VARIABLES */
    --work-buttons: 3em;

    /* MOBILE ONLY VARIABLES */
    --mobile-gradient: none;
    --homepage-button-margin: 0;
    --homepage-button-padding: 0;
    --mobile-row: column;
    --mobile-invisible: block;
}

/****** MOBILE SITE VARIABLES ******/
 @media (max-width: 800px) {  /* MOBILE SITE VARIABLES */
    :root {
    /* ALIGNMENT VARIABLES */
    --align-right-to-center: 0px;
    --mobile-inline-block: inline-block;
    --mobile-float-right: right;
    --dRight-mCenter: center;
    --dLeft-mRight: right;
    --start-to-center: center;
    --end-to-center: center;

    /* SPACING VARIABLES */
    --main-padding: 6em 0em;
    --article-padding: 2em 1.5em 6em 1.5em;
    --photo-margin: 2em;
    --mobile-only-clear: both;
    --interior-photo-padding: 0em 0em 0em 0em;
    --mobile-only-move-right: -.75em;
    --header-height: 6em;
    --first-button-padding: 0px;
    --show-on-mobile: block;
    --header-h1:opx 0px 0px 0px;

    /* SIZING VARIABLES */
    --profilePhoto: 120px;
    --contactPhoto: 200px;
    --interior-photo-scale: 100%;
    --mobile-100: 100%;

    /* TEXT AND HEADER SIZES */
    --h1-font-size: 2.5em;
    --h2-sans: 1.5em;
    --h3-font-size: 1.05em;
    --header-title-text: 8em;
    --footer-font-size: 1em;
    --footer-links: .5em;
    --nav-items: 1.15em;
    --homepage-buttons: 2em;
    --large-text: 2em;
    --standard-size-text: 1em;
    --half-size-text: .35em;
    --work-button-fonts: 1.25em;

    /* HEADER VARIABLES */
    --nav-text-top: 1.74em;
    --nav-display: block;
    --nav-margin-top: 20em;
    --navX: 0px;
    --navY: 0px;
    --navFonts: 'Palatino','Times New Roman', Times, serif;
    --header-background: linear-gradient(180deg, var(--background-color) 100%, transparent);

    /* FOOTER VARIABLES */
    --footer-icons: 1.5em;
    --footer-padding-top: none;
    --footer-height: 2em;

    /* HOMEPAGE SPECIFIC VARIABLES */
    --nav-text: 3em;

    /* INTERIOR PAGE VARIABLES */
    --interior-head: 3em;

    /* WORK PAGE VARIABLES */
    --work-buttons: 1.5em;

    /* MOBILE ONLY VARIABLES */
    /* --mobile-gradient: linear-gradient(50deg, white 0%, #6d99f9 76%);
    --homepage-button-margin: 0 -12px 32px 0px;
    --homepage-button-padding: 0 16px 0 64px; */
    --mobile-invisible: none;
    --carousel-width: 100%;
    --mobile-row: row;
    }
}
    
/* GLOBAL STYLES AND UTILITY CLASSES */
/*/////////////////////////////////////////////////////////////////////*/
    body {
        background-color: var(--background-color);
        font-family: var(--sans-fonts);
    }
    a {
        text-decoration: none;
        color: var(--dark-blue);
    }
    a:hover {
        color: var(--light-blue);
    }
    a:active {
        color: var(--light-blue);
    }
    .container {
        max-width: 100%;
        padding: 0;
    }
    /* FONT UTILITY CLASSES */
    .serif{
        font-family: var(--serif-fonts);
    }
    .sans{
        font-family: var(--sans-fonts);
    }
    .italic{
        font-style: italic;
    }
    .bold{
        font-weight: 600;
    }
    .flex-full {
      flex-basis: 1;
    }
    .flush-right {
        text-align: right;
    }
    .large-text {
        font-size: var(--large-text);
    }
    .standard-size-text {
        font-size: var(--standard-size-text);
    }    
    .half-size-text {
        font-size: var(--half-size-text);
    }
    /* COLOR UTILITY CLASSES */
    .light-blue {
        color: var(--light-blue);
    }
    .dark-blue {
        color: var(--dark-blue);
    }
/* END GLOBAL STYLES */

/***** NAVIGATION STYLES *****/
 .navbar-placement {
    position: fixed;
    right: var(--navX);
    top: var(--navY);
    z-index: 3;
    display: block;
    background-color: var(--dark-blue);
 }
 .nav-item {
    text-align: center;
    font-size: var(--nav-text-top);
    padding: .75em 1.75em;
    font-family: var(--navFonts);
    font-size: 1em;
 }
 .nav-item:hover {
    font-style: italic;
  }
 .nav-item:last-of-type {
    display: var(--show-on-mobile);
    text-align: center;
    font-size: var(--nav-text-top);
    padding: .2em 1.75em;
    color: var(--light-blue);
    font-size: 1.5em;
 }
 .shadow{
  filter: drop-shadow(.15em .15em .5em black);
 }
 .toggler {
    display: var(--show-on-mobile);
    position: fixed;
    top: 0px;
    right: 0px;
    width: 70px;
    padding-right: 35px;
    padding-top: 6px;
    min-height: 30px;
    background-color: var(--background-color);
    border-bottom: solid 1px rgba(109,153,249, .3);
    border-left: solid 1px rgba(109,153,249, .3);
    font-size: 2.25;
    color: var(--dark-blue);
    text-align: right;
 }
 .invisible-mobile {
  visibility: var(--mobile-invisible);
 }
/* END NAVIGATION STYLES */

/***** HEADER STYLES ******/
    header {
        position: fixed;
        min-width: 100%;
        height: var(--header-height);
        background-image: var(--header-background);
        z-index: 2;
        padding-top: 12px;
        padding-left: 2em;
    }
    header > * h1 {
        font-size: var(--h1-font-size);
        font-family: var(--serif-fonts);
        font-style: italic;
        margin: var(--header-h1);
        background-image: linear-gradient(135deg, rgba(238,238,238,1) 0%,rgba(238,238,238,0.25) 100%);
    }
    header > * a {
        margin-left: 12px;
        margin-right: 12px;
    }
    .home-link {
      color: black;
    }
    .home-link:hover {
      color: black;
    }
/* END HEADER STYLES */

/***** FOOTER STYLES ******/
    footer {
        position: fixed;
        bottom: 0px;
        width: 100%;
        background-color: var(--dark-blue);
        border-top: 5px solid var(--light-blue);
    }
    footer > * h4 {
        font-family: var(--serif-fonts);
        font-size: var(--footer-font-size);
        line-height: .25em;
        font-weight: bold;
        color: var(--yellow-highlight)
    }
    footer > * a {
        display: block;
        font-size: var(--footer-font-size);
        margin: .5em;
        color: var(--light-blue);
    }
    footer > * a:hover {
        display: block;
        font-size: var(--footer-font-size);
        margin: .5em;
        color: var(--white);
    }
/* END FOOTER STYLES */

/***** MAIN STYLES ******/
    main{
        padding: var(--main-padding);
        margin-left: 0px;
    }
    article > p {
      text-align: left;
    }
    article > p {
      text-align: justify;
    }
    article > * a {
      text-decoration: none;
      color: var(--light-blue);
      font-weight: bold;
    }
    article > * a:hover {
        text-decoration: underline;
        color: var(--dark-blue);
    }
    article > * a:active {
        color: var(--dark-blue);
    }
    main > * button {
        border-color: transparent;
        background-color: Transparent;
        background-image: var(--mobile-gradient);
        font-size: var(--homepage-buttons);
        font-family: var(--serif-fonts);
        margin: var(--align-right-to-center);
        color: var(--dark-blue);
        margin: var(--homepage-button-margin);
        padding: var(--homepage-button-padding);
        border-style: none;
    }
    main > * button:hover {
        font-style: italic;
        color: var(--light-blue);
    }
    .hidden-button{
      display: var(--mobile-invisible);
    }
    div.preload {
      display: none;
    }
    dl {
        display: grid;
        grid-template: "a a" auto;
        grid-gap: 0px;
    }
    dt {
        text-align: right;
        font-weight: bold;
    }
    dd {
        text-align: left;
        padding-left: 12px;
    }
    h2 {
        font-family: var(--serif-fonts);
        font-size: var(--h2-font-size);
    }
    h2.full-name {
        min-height: 2.5em;
    }
    h3 {
        font-size: var(--h3-font-size);
        font-family: var(--sans-fonts);
        /* margin-bottom: 3em; */
        line-height: var(--h3-font-size);
      }
    h4 {
    font-size: var(--h3-font-size);
    font-family: var(--serif-fonts);
    }
    article {
        padding: var(--article-padding);
    }
    aside {
        display: block;
        background-color: var(--light-blue);
        border-radius: 30px;
        text-align: center;
    }
    .work-buttons-box{
        display: flex;
        flex-direction: var(--mobile-row);
        justify-content: var(--end-to-center);
        align-items: flex-end;
        min-width: 200px;
    }
    .work-buttons {
        font-size: var(--work-button-fonts);
        background-image: none;
        margin: 12px;
        padding: 0px;
    }
    .contact-details-box{
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: var(--light-blue);
      padding-top: 32px;
      border-radius: 30px
    }
    .breadcrumbs{
      margin-bottom: 52px;
    }
    .breadcrumb-brackets{
      font-size: .8em;
      margin: 6px;
      color: var(--light-blue);
    }
    .button-contact-box{
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    button.contact{
        justify-content: center;
        align-items: center;
        width: 124px;
        height: 124px;
        margin: 10px;
        padding: 0px;
        color: var(--light-blue);
        background-image: none;
    }
    button.contact:hover {
        background-color: var(--light-blue);
        color: var(--white);
        background-image: none;   
    }
    button.portfolio {
      background-color: transparent;
      border-color: transparent;
      overflow: hidden;
      margin-top: 100px;
      min-width: 100%;
      max-height: 100%
    }
    button.portfolio:hover {
      cursor: pointer;
    }
    div.tldr-icon {
        background-color: var(--dark-blue);
        border-radius: 20px;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: .35em;
        cursor: pointer;
    }
    #mattHomepage {
      z-index: 3;
    }
    #home-media {
        overflow: hidden;
        padding: 0px;
    }
    #primary-content {
      transform: translateY(-45px);
    }
    nav{
        background-color: var(--background-color);
        font-size: var(--nav-text-top);
    }
    .navbar-toggler {
        margin: 0 auto;
    }
    .portfolio-tech-tips-in {
      animation: mainFadeIn 1.5s forwards;
      color: var(--light-blue);
    }
    .portfolio-tech-tips-out {
      animation: mainFadeOut 1.5s forwards;
      color: var(--light-blue);
    }
    .skills-box {
        background-color: var(--dark-blue);
        width: 100%;
        padding: 0px;
    }
    #spotlight{
      width: 80%;
      display: grid;
      margin: auto;
      margin-top: 24px;
    }

    /***** TOOLBOX STYLES *****/

/* END MAIN STYLES */

/***** MODAL STYLES *****/
.about-modal {
    max-height: 95vh;
    max-width: 500px;
  }
  
  .about-modal-header {
    background-color: #43598a;
    font-size: 12px;
  }
  
  .modal-image {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
  }
  .modal-content {
    border-radius: 0px;
    max-width: 95%;
  }
  .modal-close {
    position: absolute;
    top: 9px;
    right: 9px;
    color: var(--white);
    font-size: 1.5em;
  }
  .modal-art-credit {
    font-family: var(--sans-fonts);
    font-size: .5em;
    text-align: right;
    padding-right: 12px;
    padding-left: 35px;
  }
  .modal-text-padding {
    padding: 1.5em;
  }
/***** IMAGE STYLES ******/
  img.contact-photo {
    width: var(--contactPhoto);
    border-radius: 50%;
    border-width: 6px;
    border-style: solid;
    border-color: var(--yellow-highlight);
    margin-bottom: .5em;
  } 
  img.profile-photo {
    width: var(--profilePhoto);
    border-radius: 50%;
    border-width: 6px;
    border-style: solid;
    border-color: var(--light-blue);
    float: left;
    margin-right: var(--photo-margin);
    margin-left: .5em;
    margin-bottom: 2em;
    filter: drop-shadow(.15em .15em .5em black);
    cursor: "pointer";
  }
  img.profile-photo-rotate {
    width: var(--profilePhoto);
    border-radius: 50%;
    border-width: 6px;
    border-style: solid;
    border-color: var(--light-blue);
    float: left;
    margin-right: var(--photo-margin);
    margin-left: .5em;
    margin-bottom: 2em;
    filter: drop-shadow(.15em .15em .5em black);
    animation: rotate 1s linear infinite;
    transition-timing-function: ease;
  }
  img.profile-photo-rotate2 {
    width: var(--profilePhoto);
    border-radius: 50%;
    border-width: 6px;
    border-style: solid;
    border-color: #d40909;
    float: left;
    margin-bottom: 2em;
    margin-right: var(--photo-margin);
    margin-left: .5em;
    filter: drop-shadow(.15em .15em .5em black);
  }
  #interior-photo-primary {
    max-width: var(--interior-photo-scale);
    padding: var(--interior-photo-padding);
    float: var(--mobile-float-right);
  }
  img.portfolio{
    width: var(--mobile-100);
    object-fit: contain;
  }
  .tldr-image {
    max-height: 65px;
    max-width: 65px;
    display: inline;
    fill: white;
  }
  .sliding-icons {
    width: 75px;
    max-height: 75px;
    margin-bottom: 4px;
  }
  .portfolio-icons {
    max-width: 40px;
    max-height: 40px;
    margin: 4px;
  }
  .portfolio-thumbnails {
    width: 75px;
    max-height: 75px;
    margin: 4px;
  }
  .portfolio-thumbnails:hover {
    width: 75px;
    max-height: 75px;
    margin: 4px;
    border-color: var(--light-blue);
    border-style: solid;
    border-width: 4px;
    cursor: pointer;
  }
/* END IMAGE STYLES */

 /* ANIMATIONS */
 /*/////////////////////////////////////////////////////////////////////*/
   /* EFFECTS AND ANIMATION STYLES */
   .negative-ten-degrees-left {
    animation-duration: 12s;
    animation-name: slide-left;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    rotate: -10deg;
  }
  .negative-ten-degrees-right {
    animation-duration: 12s;
    animation-name: slide-right;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    rotate: -10deg;
  }
  .easeIn {
    transition: transform 30s ease-in;
    animation: mainFadeIn 5.5s forwards;
  }
  .slowFadeIn {
    animation: mainFadeIn 7.5s forwards;
  }

 @keyframes slide-left {
    from {
      transform: translateX(-100%);
    }
  
    to {
      transform: translateX(0%);
    }
  }
  
  @keyframes slide-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
  
  @keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
  @keyframes mainFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes mainFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
