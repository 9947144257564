:root {  
    /* DEFINE FONTS FOR THE SITE */
    --serif-fonts: 'Palatino','Times New Roman', Times, serif;
    --sans-fonts: hypatia-sans-pro, sans-serif;

    /* DEFINE COLORS FOR WEBSITE */
    --background-color: #F0F1F4;
    --yellow-highlight :#faf4b6;
    --light-blue: #6d99f9;
    --dark-blue: #43598a;
    --white: white;
    --black: black;

    /****** DESKTOP SITE VARIABLES ******/
    /* ALIGNMENT VARIABLES */
    --align-right-to-center: 0 0 0 auto;
    --mobile-inline-block: block;
    --mobile-float-right: none;
    --dRight-mCenter: right;
    --dLeft-mRight: left;
    --start-to-center: start;
    --end-to-center: start;
    --grid-3-1-columns: 1fr 1fr 1fr;

    /* SIZING VARIABLES */
    --profilePhoto: 200px;
    --contactPhoto: 200px;
    --interior-photo-scale: 75%;
    --mobile-100: 500px;
    --mobile-full-screen: 80%
}
@media (max-width: 800px) {  /* MOBILE SITE VARIABLES */
  :root{
    --grid-3-1-columns: 1fr;
    --mobile-full-screen: 9%
  }
}
 #portfolio{
   width: var(--mobile-full-screen);
   margin: auto;
 }
 #portfolio h2{
   font-family: var(--serif-fonts);
   font-size: 1.5em;
   padding-top: 12px;
   padding-left: 16px;
   padding-right: 16px;
   margin-bottom: 18px;
   transform: translateY(18px);
   transition: .5s;
 }
 #portfolio ul {
   display: grid;
   grid-template-columns: var(--grid-3-1-columns);
   gap: 24px;
   list-style: none;
   margin-top: 2em;
   margin-left: -2em;
   max-width: 95%;
 }
 #portfolio img {
    width: 100%;
 }
 #portfolio ul li {
    background-color: var(--background-color);
    border: 1px solid var(--dark-blue);
    color: rebeccapurple;
    text-align: center;
    transition: .5s;
 }
 #portfolio ul li:hover {
    /* background: rgb(67,89,138);
    background: linear-gradient(0deg, rgba(67,89,138,1) 0%, rgba(214,217,227,1) 35%, rgba(214,217,227,1) 70%, rgba(67,89,138,1) 100%);    margin: 6px; */
    transform: translateY(-16px);
    filter: drop-shadow(8px 8px 8px var(--dark-blue));
    /* color: white; */
    /* border: 0px; */
    transition: .5s;
 }
 #portfolio ul li p{
   visibility: visible;
   color: var(--black);
   font-size: .8em;
   font-family: var(--sans-fonts);
   margin-top: 24px;
   padding: 0px 42px;
   transform: translateY(-36px);
   transition-duration: .9s;
 }
 #portfolio button{
   width: 40px;
   height: 40px;
   margin: 6px;
   background-color: var(--light-blue);
   font-size: 1em;
   border-radius: 100%;
 }
 .portfolio-buttons{
  opacity: 0;
  height: 35px;
  transition:visibility 0.5s linear,opacity 0.5s linear;
 }
 #portfolio a:link{
  color: var(--white);
 }
 #portfolio button:hover{
  color: var(--white);
 }
 dd{
  width: 85%;
  overflow-wrap: break-word;
 }